import ApiService from './api.service';

export const TopicService = {
  query(params) {
    return ApiService.query('topics', {
      params,
    });
  },
  get(slug) {
    return ApiService.get('topics', slug);
  },
  create(params) {
    return ApiService.post('topics', { topic: params });
  },
  update(slug, params) {
    return ApiService.update('topics', slug, { topic: params });
  },
  destroy(slug) {
    return ApiService.delete(`topics/${slug}`);
  },
};

export default TopicService;
