<template>
  <div>
    <div class="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pb-2 mb-3 border-bottom">
      <div class="page-header">
        <h4 id="top">Audience: <small>{{ topic.title }}</small></h4>
      </div>
    </div>

    <!-- Error Alert -->
    <danger-alert :errors="errors" />

    <div class="form-group text-left">
      <label for="topic_name">Title</label>
      <input type="text" class="form-control" id="topic_name" v-model="topic.title" placeholder="Topic Name">
    </div>

    <div class="footer float-right">
      <div class="btn-group">
        <button @click="$router.go(-1)" class="btn btn-secondary">Cancel</button>
        <button v-on:click="createTopic" type="button" class="btn btn-success">Create Topic</button>
      </div>
    </div>
  </div>
</template>

<script>
import { TopicService } from '@/common/services/topic.service';
import DangerAlert from '@/common/components/danger-alert.vue';

export default {
  name: 'TopicNew',
  components: {
    'danger-alert': DangerAlert,
  },
  data() {
    return {
      topic: {
        title: '',
      },
      errors: [],
    };
  },
  methods: {
    createTopic() {
      TopicService.create(this.topic)
        .then((data) => {
          this.$router.push({ name: 'TopicShow', params: { id: data.id } }, () => {
            this.$notify({
              title: 'Created Activity',
              text: `Successfully created ${data.title}!`,
              type: 'success',
              width: 350,
            });
          });
        })
        .catch((error) => {
          window.scrollTo(0, document.getElementById('top').offsetTop);
          this.errors = error.response.data.error;
        });
    },
  },
};
</script>

<style lang="scss" scoped>
  @import "../../styles/views/topics";
</style>
